import { useContext, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { langContext } from './../../context/lang.context'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FormattedMessage } from 'react-intl'
import './Nav.css'


const Nav = () => {
	const idioma = useContext(langContext)
    const [isActive, setActive] = useState(false)

    const toggleClass = () => {
        setActive(!isActive)
    }
    return (
        <>
            <nav className='nav fixed-top mt-2'>
                <Link to={'/'}>
                    <img
                        src='/img/logo1.png'
                        alt='Logo The Bulliam' 
                        title='Logo The Bulliam'
                        width={'60px'}
                        height={'60px'} />
                </Link>
                <button id='buttonMenu' className='buttonMenu' onClick={toggleClass}>
                    <FontAwesomeIcon icon={faBars} />
                </button>
                <main className='navbar'>
                    <NavLink to={'/'} className={({ isActive }) => isActive ? 'selected' : ''}>
                        <FormattedMessage
                            id='nav.home'
                            defaultMessage='Inicio'/>
                    </NavLink>
                    <NavLink to={'/hangar'} className={({ isActive }) => isActive ? 'selected' : ''}>
                        <FormattedMessage
                            id='nav.code'
                            defaultMessage='Hangar Code'/>
                    </NavLink>
                    {/* <NavLink to={'/digi-art-wall'} className={({ isActive }) => isActive ? 'selected' : ''}>
                        <FormattedMessage
                            id='nav.graphic'
                            defaultMessage='Muro Digi-Art'/>
                    </NavLink> */}
                    <NavLink to={'/contact'} className={({ isActive }) => isActive ? 'selected' : ''}>
                        <FormattedMessage
                            id='nav.contact'
                            defaultMessage='Contacto'/>
                    </NavLink>
                </main> 
                <section className='lang'>
                    <button 
                        id='buttonSpanish' 
                        className='spain' 
                        onClick={() => idioma.establecerLenguaje('es-ES')}>
                        <img src="/img/spain.png" 
                             alt='Spain Button' 
                             title='Spain Button'
                             width={'50px'}
                             height={'34px'} />
                    </button>
                    <button 
                        id='buttonEnglish' 
                        className='uk' 
                        onClick={() => idioma.establecerLenguaje('en-US')}>
                        <img src="/img/uk.png" 
                             alt='UK Button' 
                             title='UK Button'
                             width={'50px'}
                             height={'34px'} />
                    </button>
                    <button 
                        id='buttonPortuguese' 
                        className='portugal' 
                        onClick={() => idioma.establecerLenguaje('pt-PT')}>
                        <img src="/img/portugal.png" 
                             alt='Portugal Button' 
                             title='Portugal Button'
                             width={'50px'}
                             height={'34px'} />
                    </button>
                </section>
            </nav>        
            <nav className={`nav-responsive fixed-top ${isActive ? 'open' : ''}`}>
                <main className={`navbar-responsive ${isActive ? 'open' : ''}`}>
                    <NavLink to={'/'} className={({ isActive }) => isActive ? 'selected' : ''}>
                        <FormattedMessage
                            id='nav.home'
                            defaultMessage='Inicio'/>
                    </NavLink>
                    <NavLink to={'/hangar'} className={({ isActive }) => isActive ? 'selected' : ''}>
                        <FormattedMessage
                            id='nav.code'
                            defaultMessage='Hangar Code'/>
                    </NavLink>
                    {/* <NavLink to={'/digi-art-wall'} className={({ isActive }) => isActive ? 'selected' : ''}>
                        <FormattedMessage
                            id='nav.graphic'
                            defaultMessage='Muro Digi-Art'/>
                    </NavLink> */}
                    <NavLink to={'/contact'} className={({ isActive }) => isActive ? 'selected' : ''}>
                        <FormattedMessage
                            id='nav.contact'
                            defaultMessage='Contacto'/>
                    </NavLink>
                    <section className='lang-responsive'>
                        <button 
                            id='responsive-spanish' 
                            className='spain' 
                            onClick={() => idioma.establecerLenguaje('es-ES')}>
                            <img src="/img/spain.png" 
                                 alt='Spain Button' 
                                 title='Spain Button'
                                 width={'50px'}
                                 height={'34px'} />
                        </button>
                        <button
                            id='responsive-english'  
                            className='uk' 
                            onClick={() => idioma.establecerLenguaje('en-US')}>
                            <img src="/img/uk.png" 
                                 alt='UK Button' 
                                 title='UK Button'
                                 width={'50px'}
                                 height={'34px'} />
                        </button>
                        <button 
                            id='responsive-portuguese' 
                            className='portugal' 
                            onClick={() => idioma.establecerLenguaje('pt-PT')}>
                            <img src="/img/portugal.png" 
                                 alt='Portugal Button' 
                                 title='Portugal Button'
                                 width={'50px'}
                                 height={'34px'} />
                            </button>
                    </section>
                    <p>
                        Copyright &copy;The Bulliam 2025
                    </p>
                </main>
            </nav>
        </>
    )
}

export default Nav