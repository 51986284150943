import { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { langContext } from './../../context/lang.context'
import { FormattedMessage } from 'react-intl'
import './Footer.css'


const Footer = () => {
	const idioma = useContext(langContext)
    return (
        <footer className='footer'>
            <nav>
                <main>
                    <NavLink to={'/'} className={({ isActive }) => isActive ? 'selected' : ''}>
                        <FormattedMessage
                            id='nav.home'
                            defaultMessage='Inicio'/>
                    </NavLink>
                    <NavLink to={'/hangar'} className={({ isActive }) => isActive ? 'selected' : ''}>
                        <FormattedMessage
                            id='nav.code'
                            defaultMessage='Hangar Code'/>
                    </NavLink>
                    {/* <NavLink to={'/digi-art-wall'} className={({ isActive }) => isActive ? 'selected' : ''}>
                        <FormattedMessage
                            id='nav.graphic'
                            defaultMessage='Muro Digi-Art'/>
                    </NavLink> */}
                    <NavLink to={'/contact'} className={({ isActive }) => isActive ? 'selected' : ''}>
                        <FormattedMessage
                            id='nav.contact'
                            defaultMessage='Contacto'/>
                    </NavLink>
                </main> 
                <section>
                    <button 
                        id='buttonFooterSpanish'
                        className='spain' 
                        onClick={() => idioma.establecerLenguaje('es-ES')}>
                        <img src="/img/spain.png" 
                             alt='Spain Button' 
                             title='Spain Button'
                             width={'50px'}
                             height={'34px'} />
                    </button>
                    <button
                        id='buttonFooterEnglish'
                        className='uk' 
                        onClick={() => idioma.establecerLenguaje('en-US')}>
                        <img src="/img/uk.png" 
                             alt='UK Button' 
                             title='UK Button'
                             width={'50px'}
                             height={'34px'} />
                    </button>
                    <button
                        id='buttonFooterPortuguese' 
                        className='portugal' 
                        onClick={() => idioma.establecerLenguaje('pt-PT')}>
                        <img src="/img/portugal.png" 
                             alt='Portugal Button' 
                             title='Portugal Button'
                             width={'50px'}
                             height={'34px'} />         
                    </button>
                </section>
            </nav>
            <p>
                Powered with React, Boostrap and Netlify
            </p>
            
            <p>
                Copyright &copy;The Bulliam 2025
            </p>
            <hr />
            <img 
                src='/img/brand.png' 
                alt='The Bulliam'
                title='Brand The Bulliam'
                // width={'1000'}
                // height={'auto'} 
                className='img-fluid mb-5' />
        </footer>
    )
}

export default Footer