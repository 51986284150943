import AppsCards from '../AppsCards/AppsCards'
import TemplatesCards from '../TemplatesCards/TemplatesCards'
import { FormattedMessage } from 'react-intl'
import './Apps.css'


const Apps = () => {
    return (
        <section className='apps'>
            <h2 data-aos='fade-right'>
                <FormattedMessage
                    id='projects.title'
                    defaultMessage='Hangar Code'/>
            </h2>
            <h3>
                <FormattedMessage
                    id='projects.text1'
                    defaultMessage='Portfolio de proyectos web y apps'/>
            </h3>
            <AppsCards />
            <hr />
            <h3>
                <FormattedMessage
                    id='projects.text2'
                    defaultMessage='Ideas de plantillas web y proyectos'/>
            </h3>
            <TemplatesCards />
        </section>
    )
}

export default Apps