import { Routes, Route } from 'react-router-dom'
import Home from '../pages/Home'
import WebApps from '../pages/WebApps'
// import GraphicDesign from '../pages/GraphicDesign'
import Contact from '../pages/Contact'


const AppRoutes = () => {
    return (
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/hangar' element={<WebApps />} />
            {/* <Route path='/digi-art-wall' element={<GraphicDesign />} /> */}
            <Route path='/contact' element={<Contact />} />
        </Routes>
    )
}

export default AppRoutes